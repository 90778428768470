export const textAlignCenter = {
  textAlign: "center",
};

export const justifyContent = {
  justifyContent: "center",
};

// const textAlignEnd = {
//   textAlign: "end",
// };

export const borderWhite = {
  // borderBottom: ".15em solid #FFFFFF",
  border: ".15em solid #FFFFFF",
};

export const displayBlock = {
  display: "block",
};

export const maxWidth = {
  maxWidth: "100%",
};
