//<!-- TODO CLEAN UP AND REFACTOR QUICK WIN
import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";

// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  displayBlock,
  textAlignCenter,
  justifyContent,
  //maxWidth,
} from "../common_styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import ProductItem from "./ProductItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
// import imageOneSrc from "assets/img/dhi_indoor_home.jpg";

const useStyles = makeStyles(styles);

//TODO - FUNC FOR THIS
const border3C4858 = {
  // borderBottom: ".15em solid #FFFFFF",
  border: ".15em solid #3C4858",
};

// const alignItems = {
//   alignItems: "center",
// };

// const iFrameParent = {
//   overflow: "hidden",
//   paddingBottom: "56.25%",
//   // position: "relative",
//   // height: "0",
// };

const flexVerticalCenter = {
  display: "flex",
  /* flex-direction: column; */
  alignItems: "center",
};

// const iframeEl = {
//   // left: "0",
//   // top: "0",
//   // height: "100%",
//   // width: "100%",
//   position: "absolute",
// };

export default function OutsideProductSection() {
  // console.log("OutsideProductSection props", props);
  const classes = useStyles();
  // const imageClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer style={justifyContent}>
          <GridItem xs={9} sm={3} md={3} style={textAlignCenter}>
            <NavLink
              to="/outdoor/deck"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Deck</h4>
            </NavLink>
          </GridItem>
          <GridItem xs={9} sm={3} md={3} style={textAlignCenter}>
            <NavLink
              to="/outdoor/pergola"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Pergola</h4>
            </NavLink>
          </GridItem>
          <GridItem xs={9} sm={3} md={3}>
            <NavLink
              to="/outdoor/patio"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Patio</h4>
            </NavLink>
          </GridItem>
        </GridContainer>
        <GridContainer style={justifyContent}>
          <Switch>
            <Route
              path="/outdoor/deck"
              render={() => (
                <GridContainer style={{ ...justifyContent, padding: "4em 0" }}>
                  <GridItem xs={12} sm={12} md={7}>
                    <iframe
                      title="outdoor-deck"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1738251149675376%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="no"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />
            <Route
              path="/outdoor/pergola"
              render={() => (
                <GridContainer style={{ ...justifyContent, padding: "4em 0" }}>
                  <GridItem xs={12} sm={12} md={7}>
                    <iframe
                      title="outdoor-pergola"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1738253233008501%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="no"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />

            <Route
              path="/outdoor/patio"
              render={() => (
                <GridContainer style={{ ...justifyContent, padding: "4em 0" }}>
                  <GridItem xs={12} sm={12} md={7}>
                    <iframe
                      title="outdoor-patio"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1738251683008656%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="no"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                      allowFullScreen={true}
                    ></iframe>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />
          </Switch>
          {/* <GridItem xs={12} sm={6} md={3}>
            <div className="mcwidget-embed" data-widget-id="13269884"></div>
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}
