import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, Switch, Route } from "react-router-dom";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";

import OutsideProductSection from "./Sections/OutsideProductSection.js";

import {
  textAlignCenter,
  justifyContent,
  borderWhite,
  displayBlock,
} from "./common_styles";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// const textAlignStart = {
//   textAlign: "start",
// };

// const activeAnchor = {
//   ...bottomBorderWhite,
//   ...textAlignCenter,
//   ...displayBlock,
// };

// const createBorder = ({height, line, color}) => `${height} ${line} ${color}`;

// type imgKey = 'inside' | 'outside';
const loadAssetMap = (map) => (imgKey) => map[imgKey];
// const getIndoorOutdoorImg = loadAssetMap({
//   inside: "assets/img/landing-bg.jpg",
//   outside: "assets/img/dhi_home.jpg",
// });

// const backgroundImage = {
//   backgroundImage: "url(" + "assets/img/landing-bg.jpg" + ")",
// };

// <!-- LANDING PAGE WILL BE THE STATEFUL CONTAINER
// <!-- RESPONSOBLE FOR FETCHING PICS

// const homePics = [

// ]

// const productPics = [{ src: "assets/img/dhi_indoor_home.jpg" }];

const getIndoorOutdoorImg = loadAssetMap({
  indoor: require("assets/img/dhi_indoor_home.jpg"),
  outdoor: require("assets/img/dhi_outdoor_home.jpg"),
});

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  //const [pics] = React.useState(productPics);
  //"indoor"
  // const [asset, setAsset] = React.useState(
  //   getIndoorOutdoorImg("indoor")
  //   // getIndoorOutdoorImg(rest.match.split("/")[1].trim().toLowerCase())
  // );
  // const changeLandingBackground = (imgType) =>
  //   setAsset(getIndoorOutdoorImg(imgType));

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Dolan Home Improvement"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      {/* {require(asset)} style={backgroundImage} */}
      {/* image={require("assets/img/dhi_home.jpg")} */}
      <Parallax
        filter
        image={getIndoorOutdoorImg(
          rest.match.path.split("/")[1].trim().toLowerCase()
        )}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={textAlignCenter}>
              <h1 className={classes.title}>
                Your Dreams. Our Ingenuity.
                {/* Your Dreams. Our Ingenuity. Possibilities. Limitless. */}
                {/* Our Ingenuity = Your dreams coming true. */}
              </h1>
              {/* <h4>Let's team up to make your dreams a reality.</h4> */}
              {/* <h1 className={classes.title}>Your dreams. Our Ingenuity.</h1> */}
            </GridItem>
          </GridContainer>
          <GridContainer style={justifyContent}>
            <GridItem
              xs={10}
              sm={3}
              md={3}
              // style={{ ...bottomBorderWhite, ...textAlignCenter }}
              style={textAlignCenter}
              // changeLandingBackground("indoor")
              //onClick={() => changeLandingBackground("indoor")}
            >
              <NavLink
                to="/indoor"
                style={displayBlock}
                activeStyle={borderWhite}
              >
                <h4 className={classes.title}>Indoor</h4>
              </NavLink>
            </GridItem>
            <GridItem
              xs={10}
              sm={3}
              md={3}
              style={textAlignCenter}
              //onClick={() => changeLandingBackground("outdoor")}
            >
              <NavLink
                to="/outdoor"
                style={displayBlock}
                activeStyle={borderWhite}
              >
                <h4 className={classes.title}>
                  Outdoor
                  {/* Outdoor */}
                </h4>
              </NavLink>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* <ProductSection /> */}
          <Switch>
            <Route
              path="/indoor"
              render={() => (
                // pass the sub-routes down to keep nesting
                <ProductSection {...props} routes={[{ path: "/indoor" }]} />
              )}
            />
            <Route
              path="/outdoor"
              render={() => (
                // pass the sub-routes down to keep nesting
                <OutsideProductSection
                  {...props}
                  routes={[{ path: "/outdoor" }]}
                />
              )}
            />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

// <GridItem xs={12} sm={6} md={3}>
//             <div className="mcwidget-embed" data-widget-id="13269884"></div>
//           </GridItem>
//           <GridContainer>
//             <GridItem xs={12} sm={12} md={12}>
//               <div className="mcwidget-embed" data-widget-id="13269884"></div>
//             </GridItem>
//           </GridContainer>
//           <TeamSection />
//           <WorkSection />
