import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";

// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  displayBlock,
  textAlignCenter,
  justifyContent,
  // maxWidth,
} from "../common_styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
// import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
// import ProductItem from "./ProductItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
// import imageOneSrc from "assets/img/dhi_indoor_home.jpg";

// import indoorOne from "assets/img/indoor_one_one.jpg";
// import indoorTwo from "assets/img/indoor_one_two.jpg";
// import indoorThree from "assets/img/indoor_one_three.jpg";
// import indoorFour from "assets/img/indoor_one_four.jpg";

const useStyles = makeStyles(styles);

//TODO - FUNC FOR THIS
const border3C4858 = {
  // borderBottom: ".15em solid #FFFFFF",
  border: ".15em solid #3C4858",
};

// const alignItems = {
//   alignItems: "center",
// };

// const iFrameParent = {
//   overflow: "hidden",
//   paddingBottom: "56.25%",
//   // position: "relative",
//   // height: "0",
// };

const flexVerticalCenter = {
  display: "flex",
  /* flex-direction: column; */
  alignItems: "center",
};

// const iframeEl = {
//   // left: "0",
//   // top: "0",
//   // height: "100%",
//   // width: "100%",
//   position: "absolute",
// };

// function ProductList(props) {
//   return <div>list {props}</div>;
// }

// const indoorPics = [indoorOne, indoorTwo, indoorThree, indoorFour];
export default function ProductSection() {
  // console.log("ProductSection props", props);
  const classes = useStyles();
  // const imageClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer style={justifyContent}>
          <GridItem xs={9} sm={3} md={3} style={textAlignCenter}>
            <NavLink
              to="/indoor/bath"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Bath</h4>
            </NavLink>
          </GridItem>
          <GridItem xs={9} sm={3} md={3} style={textAlignCenter}>
            <NavLink
              to="/indoor/kitchen"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Kitchen</h4>
            </NavLink>
          </GridItem>
          <GridItem xs={9} sm={3} md={3}>
            <NavLink
              to="/indoor/basement"
              style={displayBlock}
              activeStyle={border3C4858}
            >
              <h4 className={classes.title}>Basement</h4>
            </NavLink>
          </GridItem>
        </GridContainer>

        <GridContainer style={{ ...justifyContent, padding: "4em 0" }}>
          {/* <GridItem xs={12} sm={12} md={12}>
            <h5 className={classes.title}>
              Connect with us on facebook to see more
            </h5>
          </GridItem> */}
          <Switch>
            <Route
              path="/indoor/bath"
              render={() => (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    {/* <Card plain style={{ ...alignItems, ...iFrameParent }}> */}
                    {/* <GridItem */}
                    {/* xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}> */}
                    <iframe
                      title="indoor-bath"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1738249419675549%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="yes"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                    {/* </GridItem> */}
                    {/* <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div> */}
                    {/* </Card> */}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />
            <Route
              path="/indoor/kitchen"
              render={() => (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <iframe
                      title="indoor-kitchen"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1738250083008816%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="no"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />

            <Route
              path="/indoor/basement"
              render={() => (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <iframe
                      title="indoor-basement"
                      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedia%2Fset%2F%3Fset%3Da.1739067762927048%26type%3D3&width=500"
                      width="500"
                      height="621"
                      //style={iframeEl} //"border:none;overflow:hidden"
                      scrolling="no"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={flexVerticalCenter}>
                    <div
                      className="mcwidget-embed"
                      data-widget-id="13269884"
                    ></div>
                  </GridItem>
                </GridContainer>
              )}
            />
          </Switch>
          {/* <GridItem xs={12} sm={6} md={3}>
            <div className="mcwidget-embed" data-widget-id="13269884"></div>
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}

// indoorPics.map((src, index) => (
//   <GridItem key={index} xs={12} sm={12} md={4}>
//     <Card plain>
//       <GridItem
//         xs={12}
//         sm={12}
//         md={12}
//         className={classes.itemGrid}
//       >

/* <img
          src={src}
          alt="..."
          className={imageClasses}
          style={maxWidth}
        /> */

/* <iframe
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FDolanHomeImprovement%2Fphotos%2Fa.1736600526507105%2F1736600553173769%2F%3Ftype%3D3&width=500"
          width="500"
          height="380"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe> */

/* </GridItem> */

/* <h4 className={classes.cardTitle}>Basement Theatre</h4>
      <CardBody>
        <p className={classes.description}>
          A short description of the job Renasaince Renovation
          Bathroom
        </p>

  /* </Card>
  </GridItem>
)) */

// {/* <GridItem xs={12} sm={12} md={8}>
//   <h2 className={classes.title}>Let{"'"}s talk product</h2>
//   <h5 className={classes.description}>
//     This is the paragraph where you can write more details about your
//     product. Keep you user engaged by providing meaningful information.
//     Remember that by this time, the user is curious, otherwise he wouldn
//     {"'"}t scroll to get here. Add a button if you want the user to see
//     more.
//   </h5>
// </GridItem> */}

/* <div>
<GridContainer>
  <GridItem xs={12} sm={12} md={4}>
    <Card plain>
      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
        <img
          src={imageOneSrc}
          alt="..."
          className={imageClasses}
          style={maxWidth}
        />
      </GridItem>
      <h4 className={classes.cardTitle}>
        Contemporary Bathroom Remodel
      </h4>
      <CardBody>
        <p className={classes.description}>
          A short description of the job Contemporary Bathroom Remodel
        </p>
      </CardBody>
    </Card>
  </GridItem>
  <GridItem xs={12} sm={12} md={4}>
    <Card plain>
      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
        <img
          src={imageOneSrc}
          alt="..."
          className={imageClasses}
          style={maxWidth}
        />
      </GridItem>
      <h4 className={classes.cardTitle}>Modern Kitchen Remodel</h4>
      <CardBody>
        <p className={classes.description}>
          A short description of the job Modern Bathroom Remodel
        </p>
      </CardBody>
    </Card>
  </GridItem>
  <GridItem xs={12} sm={12} md={4}>
    <Card plain>
      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
        <img
          src={imageOneSrc}
          alt="..."
          className={imageClasses}
          style={maxWidth}
        />
      </GridItem>
      <h4 className={classes.cardTitle}>Basement Theatre</h4>
      <CardBody>
        <p className={classes.description}>
          A short description of the job Renasaince Renovation Bathroom
        </p>
      </CardBody>
    </Card>
  </GridItem>
</GridContainer>
</div> */
